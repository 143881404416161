import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import {
  font_family_classes,
  google_font_family_classes,
} from "../../ui_libs/Constants/constant";
import { Skeleton } from "@mui/material";
import { useBuilder } from "../../hooks/builder-context/BuilderContext";
export const get_formatted_font_size = () => {
  let formatted_string =
    "10px=0.625rem 12px=0.75rem 14px=0.875rem 15px=0.94rem 16px=1rem 18px=1.125rem 20px=1.25rem 22px=1.375rem 24px=1.5rem 28px=1.75rem 32px=2rem 36px=2.25rem 40px=2.5rem 44px=2.75rem 48px=3rem 56px=3.5rem 64px=4rem 72px=4.5rem 80px=5rem 88px=5.5rem 96px=6rem 100px=6.25rem 110px=6.88rem 120px=7.50rem 130px=8.13rem 140px=8.75rem 150px=9.38rem";
  return formatted_string;
};

export const get_formatted_fonts = () => {
  let formatted_string = "";
  sessionStorage.getItem("is_dev") === "true" &&
    [1, 2, 3, 4].map((item) => {
      let single_font = `Default H${item}=var(--wb-theme-h${item}-font-family);`;
      formatted_string += single_font;
    });

  google_font_family_classes.forEach((font) => {
    let single_font = `${font.label}=${font.css_rule};`;
    formatted_string += single_font;
  });
  return formatted_string;
};

function CustomEditor({ mode, content, setContent, editorRef }) {
  const { rootValues } = useBuilder();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(content);
  const { get_public_key } = useBuilder();
  const [tinyMCEKey, setTinyMCEKey] = useState("");

  const get_content_style = () => {
    let formatted_string = "";
    sessionStorage.getItem("is_dev") === "true" &&
      [1, 2, 3, 4].map((item) => {
        let single_font =
          "@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');";
        formatted_string += single_font;
      });

    google_font_family_classes.forEach((font) => {
      let single_font = `${font.import_link}`;
      formatted_string += single_font;
    });

    formatted_string += `body { background-color: #02285912;  } h1 { font-family: ${rootValues.theme_h1_font_family}; } h2 {font-family: ${rootValues.theme_h2_font_family}; }
    h3 { font-family:  ${rootValues.theme_h3_font_family};}  h4 { font-family: ${rootValues.theme_h4_font_family}; } p { font-family: ${rootValues.font_family}; }`;

    return formatted_string;
  };

  // const get_formatted_font_size = () => {
  //   let formatted_string =
  //     "10px=0.625rem 12px=0.75rem 14px=0.875rem 16px=1rem 18px=1.125rem 20px=1.25rem 22px=1.375rem 24px=1.5rem 28px=1.75rem 32px=2rem 36px=2.25rem 40px=2.5rem 44px=2.75rem 48px=3rem 56px=3.5rem 64px=4rem 72px=4.5rem 80px=5rem 88px=5.5rem 96px=6rem";
  //   return formatted_string;
  // };

  const remove_editor_branding = () => {
    const branding = document.getElementsByClassName("tox-statusbar__branding");
    if (branding.length > 0) {
      branding[0].remove();
    }
  };

  useEffect(() => {
    remove_editor_branding();
  }, []);

  // if (isLoading) {
  //   return <></>;
  // }

  useEffect(async () => {
    const key = await get_public_key("tinymc_key");
    setTinyMCEKey(key);
  }, []);

  if (!tinyMCEKey) {
    return (
      <div>
        <Skeleton variant="rect" className="mb-2" />
        <Skeleton variant="rect" className="mb-2" />
        <Skeleton variant="rect" className="mb-2" />
        <Skeleton variant="rect" className="mb-2" />
        <Skeleton variant="rect" className="mb-2" />
        <Skeleton variant="rect" className="mb-2" />
        <Skeleton variant="rect" className="mb-2" />
        <Skeleton variant="rect" className="mb-2" />
        <Skeleton variant="rect" className="mb-2" />
        <Skeleton variant="rect" className="mb-2" />
        <Skeleton variant="rect" className="mb-2" />
      </div>
    );
  }

  return (
    <div>
      <Editor
        apiKey={tinyMCEKey}
        onInit={(evt, editor) => {
          editorRef.current = editor;
          console.log(editor, "chchchhchchchchchh");

          // remove_editor_branding();
        }}
        initialValue={content}
        init={{
          //   images_upload_handler: example_image_upload_handler,
          height: 500,
          menubar: false,
          // casechange
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "charmap",
            "print",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "paste",
            "code",
            "help",
            "wordcount",
          ],
          menu: {
            file: {
              title: "File",
              items: "newdocument restoredraft | preview | print ",
            },
            edit: {
              title: "Edit",
              items: "undo redo | cut copy paste | selectall | searchreplace",
            },
            view: {
              title: "View",
              items:
                "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen",
            },
            insert: {
              title: "Insert",
              items:
                "image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime",
            },
            format: {
              title: "Format",
              items:
                "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat",
            },
            tools: {
              title: "Tools",
              items: "spellchecker spellcheckerlanguage | code wordcount",
            },

            help: { title: "Help", items: "help" },
          },
          // casechange
          toolbar:
            "undo redo | fontfamily blocks fontsize  removeformat | " +
            "forecolor backcolor fontweight link italic textshadow underline strikethrough subscript superscript lineheight alignnone alignleft aligncenter alignright alignjustify outdent indent numlist bullist" +
            // "numlist bullist " +
            "",
          toolbar_mode: "wrap",
          font_size_formats: get_formatted_font_size(),
          font_family_formats: get_formatted_fonts(),
          content_style: get_content_style(),

          setup: function (editor) {
            editor.ui.registry.addIcon(
              "shadowIcon",
              '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 3h13v3h-5v12H8V6H3zm9 4h2v2h-2zm3 0h2v2h-2zm3 0h2v2h-2zm-6 3h2v2h-2zm0 3h2v2h-2zm0 3h2v2h-2zm0 3h2v2h-2z"/></svg>'
            );

            editor.ui.registry.addButton("textshadow", {
              icon: "shadowIcon",
              tooltip: "Text Shadow",
              onAction: function () {
                const selectedNode = editor.selection.getNode();
                const currentTextShadow = selectedNode.style.textShadow;

                // Default values
                let offsetX = "";
                let offsetY = "";
                let blurRadius = "";
                let color = "";

                if (currentTextShadow) {
                  const textShadowParts = currentTextShadow.match(
                    /(.*\S)\s+(-?\d+px)\s+(-?\d+px)\s+(\d+px)/
                  );
                  if (textShadowParts) {
                    color = textShadowParts[1];
                    offsetX = textShadowParts[2].replace("px", "");
                    offsetY = textShadowParts[3].replace("px", "");
                    blurRadius = textShadowParts[4].replace("px", "");
                  }
                }

                const buttons = [
                  {
                    type: "cancel",
                    text: "Cancel",
                  },
                  // Add Remove button if currentTextShadow exists
                  ...(currentTextShadow && currentTextShadow !== "none"
                    ? [
                        {
                          type: "custom",
                          text: "Remove",
                          primary: false,
                          name: "removeButton",
                        },
                      ]
                    : []),
                  {
                    type: "submit",
                    text: "Apply",
                    primary: true,
                  },
                ];

                editor.windowManager.open({
                  title: "Text Shadow",
                  body: {
                    type: "panel",
                    items: [
                      {
                        type: "input",
                        name: "offsetX",
                        label: "Offset X",
                        value: offsetX,
                      },
                      {
                        type: "input",
                        name: "offsetY",
                        label: "Offset Y",
                        value: offsetY,
                      },
                      {
                        type: "input",
                        name: "blurRadius",
                        label: "Blur Radius",
                        value: blurRadius,
                      },
                      {
                        type: "input",
                        name: "color",
                        label: "Color",
                        // inputMode: "color",
                        value: color,
                      },
                    ],
                  },

                  buttons: buttons,
                  initialData: {
                    // Ensure initialData is used to populate the values
                    offsetX: offsetX,
                    offsetY: offsetY,
                    blurRadius: blurRadius,
                    color: color,
                  },
                  onSubmit: function (api) {
                    const data = api.getData();
                    const isValid =
                      data.offsetX &&
                      data.offsetY &&
                      data.blurRadius &&
                      data.color;

                    if (isValid) {
                      const textShadowValue = `${data.offsetX}px ${data.offsetY}px ${data.blurRadius}px ${data.color}`;
                      applyTextShadow(textShadowValue);
                    } else {
                      // Remove text-shadow if any value is invalid
                      if (currentTextShadow) {
                        applyTextShadow("");
                      }
                    }
                    api.close();
                  },

                  onAction: function (api, details) {
                    if (details.name === "removeButton") {
                      applyTextShadow("");
                      api.close();
                    }
                  },
                });
              },
            });

            // Add font weight dropdown
            editor.ui.registry.addMenuButton("fontweight", {
              icon: "bold",
              tooltip: "Font Weight",
              fetch: function (callback) {
                const items = [
                  {
                    type: "menuitem",
                    text: "Normal",
                    onAction: () => applyFontWeight("400"),
                  },
                  {
                    type: "menuitem",
                    text: "Bold",
                    onAction: () => applyFontWeight("700"),
                  },
                  {
                    type: "menuitem",
                    text: "Bolder",
                    onAction: () => applyFontWeight("900"),
                  },
                  {
                    type: "menuitem",
                    text: "Lighter",
                    onAction: () => applyFontWeight("200"),
                  },
                  {
                    type: "menuitem",
                    text: "100",
                    onAction: () => applyFontWeight("100"),
                  },
                  {
                    type: "menuitem",
                    text: "200",
                    onAction: () => applyFontWeight("200"),
                  },
                  {
                    type: "menuitem",
                    text: "300",
                    onAction: () => applyFontWeight("300"),
                  },
                  {
                    type: "menuitem",
                    text: "400",
                    onAction: () => applyFontWeight("400"),
                  },
                  {
                    type: "menuitem",
                    text: "500",
                    onAction: () => applyFontWeight("500"),
                  },
                  {
                    type: "menuitem",
                    text: "600",
                    onAction: () => applyFontWeight("600"),
                  },
                  {
                    type: "menuitem",
                    text: "700",
                    onAction: () => applyFontWeight("700"),
                  },
                  {
                    type: "menuitem",
                    text: "800",
                    onAction: () => applyFontWeight("800"),
                  },
                  {
                    type: "menuitem",
                    text: "900",
                    onAction: () => applyFontWeight("900"),
                  },
                ];
                callback(items);
              },
            });

            // Register font weight formats and text-shadow
            editor.on("init", () => {
              editor.formatter.register("fontweight", {
                inline: "span",
                styles: { "font-weight": "%value" },
              });
              editor.formatter.register("textShadow", {
                inline: "span",
                styles: { "text-shadow": "%value" },
              });
            });

            // Apply font Weight
            function applyFontWeight(weight) {
              editor.formatter.apply("fontweight", { value: weight });
            }

            // Apply Text Shadow
            function applyTextShadow(shadow) {
              if (shadow) {
                editor.formatter.apply("textShadow", { value: shadow });
              } else {
                editor.formatter.apply("textShadow", { value: "none" });
              }
            }
          },
        }}
      />
    </div>
  );
}

export default CustomEditor;
