// common business logic related util methods
import axios from "axios";
import { base_uri, client_side_url } from "../config/config";
import { userTypes } from "../ui_libs/Constants/constant";

axios.defaults.headers.post["Content-Type"] = "application/json";

export async function invokeApi({
  path,
  base_url = base_uri,
  method = "GET",
  headers = {},
  queryParams = {},
  postData = {},
  completeUrl = null,
}) {
  const reqObj = {
    method,
    url: completeUrl ? completeUrl : base_url + path,
    headers,
  };

  reqObj.params = queryParams;

  if (method === "POST") {
    reqObj.data = postData;
  }
  if (method === "PUT") {
    reqObj.data = postData;
  }
  if (method === "DELETE") {
    reqObj.data = postData;
  }

  let results;

  console.log("<===REQUEST-OBJECT===>", reqObj);

  try {
    results = await axios(reqObj);
    console.log("<===Api-Success-Result===>", results);

    return completeUrl ? results : results.data;
  } catch (error) {
    if (completeUrl) {
      console.log("<===Api-Error===>", reqObj, error);
    } else {
      console.log("<===Api-Error===>", reqObj, error.response.data);
    }

    if (!completeUrl && error.response.status === 401) {
      // if (sessionStorage.getItem("userType") == userTypes.vissioon) {
        sessionStorage.clear();
        alert(`Access Denied: \nYou do not have the necessary permissions to acces builder.`);
        window.close();
      // }else{
      //   sessionStorage.clear();
      //   window.location.replace(client_side_url + "/login");
      // }
      // window.location.reload();
    }
    return completeUrl
      ? {
          status: 201,
          message: "Something Went Wrong",
        }
      : {
          code: error.response.status,
          message: error.response.data.message
            ? error.response.data.message
            : "",
        };
  }
}
