// here we add all base urls and keys

// Base URLS
export const base_uri = process.env.REACT_APP_API_BASE_URL;
export const vissioon_base_url = process.env.REACT_APP_VISSIOON_API_BASE_URL;
export const source_base_uri = process.env.REACT_APP_API_SOURCE_BASE_URL;
export const s3baseUrl = process.env.REACT_APP_PIC_BASE_URL;
export const s3builderSource = process.env.REACT_APP_S3_SOURCE_BASE_URL;
export const s3builderSourceForFiles =
  process.env.REACT_APP_S3_SOURCE_ASSERTS_BASE_URL_S3_FILES;
export const tinyMCEKey = process.env.REACT_APP_TINY_MCE_KEY;
export const pexelsImagesKey = process.env.REACT_APP_PEXEL_IMAGES_KEY;
export const base_folder = "users";
export const app_type = "1";

export const admin_api_key = process.env.REACT_APP_ADMIN_API_KEY;
export const asserts_base_url =
  process.env.REACT_APP_S3_SOURCE_ASSERTS_BASE_URL;

export const client_side_url = process.env.REACT_APP_CLIENT_SIDE_URL;

// JS Files URL
export const main_js = process.env.REACT_APP_JS_MAIN_FILE_NAME;
export const payment_js = process.env.REACT_APP_JS_PAYMENT_FILE_NAME;

// Keys
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;

// Dev States
export const book_a_call_sections = false; //Show Book a call for single page project
export const box_drawer_option = false;
export const not_published_sections = false; //Sections that show only in developer mode
export const button_editor_dev_mode = false;
export const text_shadow_option = false;
export const custom_border_radius = false;
export const editor_drawer_dev_mode = false;
export const icon_editor_dev_mode = false;
export const image_editor_dev_mode = false;
export const text_editor_dev_mode = false;
export const right_menu_drawer_dev_mode = false;
export const section_setting_drawer_dev_mode = false;
export const section_content_change_button = false;
export const ai_content_change_option_top_bar = false;
export const ai_logo_generator = false;
export const text_setting_left_drawer_dev_mode = false;
export const row_setting_gradient_option = false;
export const column_setting_width_option = false; // width option, percentage or columns default is percentage
export const theme_selection_advance_color_option = false;
export const overlay_advance_color_option = true;
export const pexel_images_option = false;
export const delete_all_images_option = false;
export const icon_height_width_option = false;
export const button_editor_icon_option = true;
export const box_setting_shadow_option = false;
export const button_setting_shadow_option = false;
export const absolute_position_handle = false;
export const button_color_component_advance_color_option = false; // almost All color options are used through button property component
export const owl_slider_spacing_option = false;
export const column_spacing_margin_option = false;
export const accordian_button_setting_option = false;
